import React, { useEffect, useState } from 'react'
import { platforms } from '.'
import PageHeader from 'components/common/PageHeader'
import SocialPlatformItems from './SocialPlatformItems'
import Flex from 'components/common/Flex'
import APIService from 'http/api_service'
import { toast } from 'react-toastify'
import SoftBadge from 'components/common/SoftBadge'
import { useSelector } from 'react-redux'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'

const PlatformPageNew = () => {
  const [socialPlatforms, setSocialPlatforms] = useState(platforms);
  const [originalSocialPlatforms, setOriginalSocialPlatforms] = useState(platforms);
  const [showLess, setShowLess] = useState(true);
  const [platformCountStatementData, setPlatformCountStatementData] = useState(null);
  const workSpace = useSelector(getActiveWorkSpace);
  const workSpaceId = workSpace?._id

  const getPlatformCountStatement = () => {
    APIService.fetchUserPlatformsCountStatement(workSpaceId, (response, error) => {
      if (error) {
        // toast.error(error, { theme: 'colored' });
        return;
      }
      const { data } = response;
      setPlatformCountStatementData(data);
    });
  }

  useEffect(() => {
    getPlatformCountStatement()
  }, [])


  return (
    <>
      <PageHeader
        title=""
        titleTag="h5"
        className="mb-3"
      >
        <Flex
          alignItems={'center'}
          gap={2}
        >
          {
            platformCountStatementData &&
            <SoftBadge pill bg={platformCountStatementData?.color} className='me-2 cursor-pointer'>
              {platformCountStatementData?.statement}
            </SoftBadge>
          }
        </Flex>
      </PageHeader>
      {
        socialPlatforms.map((platform, index) => (
          <SocialPlatformItems
            platform={platform}
            key={index}
            showLess={showLess}
            setShowLess={setShowLess}
          />
        ))
      }
    </>
  )
}

export default PlatformPageNew