import Deals from "components/app/resources/deals";
import Help from "components/app/resources/help";
import PostsLayout from "components/app/social/feed/Feed";
import DraftedPost from "components/app/social/feed/draft_posts";
import FailedPost from "components/app/social/feed/failed_posts";
import PendingApprovalPosts from "components/app/social/feed/pending_approval_posts";
import PublishedPost from "components/app/social/feed/published_posts";
import RecurringPosts from "components/app/social/feed/recurring_posts";
import ScheduledPost from "components/app/social/feed/scheduled_posts";
import WorkspaceSwitcher from "components/app/switch";
import AccountLogOut from "components/authentication/card/Logout";
import PasswordLessLogIn from "components/authentication/card/PasswordLess";
import CardPasswordReset from "components/authentication/card/PasswordReset";
import VerifyEmail from "components/authentication/email/verify_email";
import WorkspaceInvitations from "components/authentication/invitations";
import CardForgetPassword from "components/authentication/simple/ForgetPassword";
import AccountLogIn from "components/authentication/simple/Login";
import AccountRegister from "components/authentication/simple/Registration";
import { CloseButton } from "components/common/Toast";
import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";
import AppContext from "context/Context";
import is from "is_js";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { RoutePaths } from "../constants";
import AuthLayout from "./AuthLayout";
import ErrorLayout from "./ErrorLayout";
import MainLayout from "./MainLayout";
import SplashLayout from "./SplashLayout";
// import AuthSimpleLayout from "./AuthSimpleLayout";
import NewWorkspace from "components/app/new-workspace";
import OrganizationAndWorkspaceSetup from "components/app/organization-and-workspace-setup";
import PlatformsPage from "components/app/platforms";
import ConnectedAccountsPage from "components/app/platforms/connected_accounts";
import ConnectedAccountsDataPage from "components/app/platforms/connected_accounts/connected_account_data_page";
import WorkspaceSettings from "components/pages/workspace/settings/Settings";
// import AIWriter from "components/app/ai-writer";
import AccountPage from "components/app/account";
import RedeemCodePage from "components/app/account/credits";
import InvoiceHistoryPage from "components/app/account/invoice";
import BillingPage from "components/app/account/pricing";
import AIArtGenerator, { art_tools } from "components/app/ai-art";
import { tools } from "components/app/ai-writer";
import AIWriterTool from "components/app/ai-writer/ai_writer_tool";
import TeamPage from "components/app/team";
import MandatoryAccountUpgrade from "components/app/account/billing-stripe/MandatoryPlanUpgrade";
import BillingStripe from "components/app/account/billing-stripe/index";
import PostlyCloudBilling from "components/app/account/pricing/PostlyCloudBillingPage";
import UnlimitedAIBilling from "components/app/account/pricing/UnlimitedAIBilling";
import WhiteLabelSettings from "components/app/account/profile/WhiteLabelSettings";
import WhiteLabelSetup from "components/app/account/whitelabel/WhiteLabelWizard";
import AIArtTool from "components/app/ai-art/ai_art_tool";
import FacebookAnalytics from "components/app/analytics/platforms/facebook";
import InstagramAnalytics from "components/app/analytics/platforms/instagram";
import LinkedInAnalytics from "components/app/analytics/platforms/linked_in";
import YoutubeAnalytics from "components/app/analytics/platforms/youtube";
import BulkWorkspacesCreator from "components/app/bulk-workspaces";
import SocialInbox from "components/app/chat/Chat";
import ContentPlanner from "components/app/content-planner/Calendar";
import RSSFeed from "components/app/rss/feeds";
import CreatePoll from "components/app/social/feed/CreatePoll";
import BulkPostsCreator from "components/app/social/feed/bulk-posts";
import GoogleDrivePage from "components/app/social/feed/previewer/GoogleDrivePage";
import MediaCloud from "components/app/social/feed/previewer/MediaCloud";
import PexelsPage from "components/app/social/feed/previewer/PexelsPage";
import ShutterStockPage from "components/app/social/feed/previewer/ShutterStockPage";
import ModalAuth from "components/authentication/modal/ModalAuth";
import VerifyEmailAddressOnSignup from "components/authentication/simple/VerifyEmailAddressOnSignup";
import CampaignBuilder from "components/tools/campaign_builder";
import ImageBackgroundRemoval from "components/tools/image_bg_removal.js";
import ImageEditorPage from "components/tools/image_editor";
import ImageEditor from "components/tools/image_editor_fullscreen";
import LinkShortener from "components/tools/link_shortener";
import CustomDomainSettingsPage from "components/tools/link_shortener/CustomDomain";
import Integrations from "components/tools/link_shortener/Integrations";
import Qrcode from "components/tools/qrcode";
import ReviewLinkGenerator from "components/tools/review_link_generator";
import VideoEditorPage from "components/tools/video_editor";
import VideoEditorFunctions from "components/tools/video_editor_functions";
import { useSelector } from "react-redux";
import { getLoggedInUser } from "redux/slices/user_slice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import AllBilling from "../components/app/account/pricing/AllBilling";
import LTDPurchasePage from "../components/app/account/pricing/LTDPurchasePage";
import LTDUpgradePage from "../components/app/account/pricing/LTDUpgradePage";
import SchedulerBilling from "../components/app/account/pricing/SchedulerBilling";
import PaymentPortalProxy from "./PaymentPortalProxy";
import TelegramLoginProxy from "./TelegramLogInProxy";
// import PreviewPageEditor from 'components/app/social/feed/PreviewPageEditor'
import AIChat from "components/app/ai-options/chat";
import AISettings from "components/app/ai-options/settings";
import BusinessLocations from 'components/tools/google_business_locations';
// import PostWriter from "components/app/ai-options/writer/post_writer";
import VideoEditor from "components/app/ai-options/editor/video_editor";
import LanguageTranslator from "components/app/ai-options/language_translator";
import EventCalendar from "components/app/content-planner/EventCalendar";
import Metrics from "components/admin/metrics";
import Users from "components/admin/users/users";
import Workspaces from "components/admin/workspaces/workspaces";
import BotRequests from "components/admin/bot-requests";
import GlobalUpgradeModal from "components/common/GlobalUpgradeModal";
import PlatformPageNew from "components/app/platforms/PlatformPageNew";
import WhiteLabelStripeConnectPricingPage from "components/app/account/whitelabel/WhiteLabelStripeConnectPricingPage";
import StripeConnect from "components/app/account/stripe-connect";

const Layout = () => {
  const [meta, setMeta] = useState("");
  const [canRoute, setCanRoute] = useState(false);
  const activeWorkSpace = useSelector(getActiveWorkSpace);
  const loggedInUser = useSelector(getLoggedInUser);
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const { setConfig } = useContext(AppContext);

  const initUserThemeChoice = () => {
    let themeChoices = activeWorkSpace?.theme_choices ?? {};
    if (loggedInUser) {
      let userId = loggedInUser._id;
      let userThemeChoice = themeChoices[userId];
      if (userThemeChoice) {
        setConfig("isDark", userThemeChoice === "dark");
      }
    }
  };

  useEffect(() => {
    initUserThemeChoice();
    let params = new URL(document.location).searchParams;
    let metaParams = params.get("meta");
    if (metaParams) {
      setMeta(metaParams);
    }
    setCanRoute(true);
  }, []);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
  }, [HTMLClassList]);

  return (
    <>
      {canRoute && (
        <Routes>
          <Route path="/" element={<SplashLayout />} />
          <Route
            path={RoutePaths.IMAGE_EDITOR_FULLSCREEN}
            element={<ImageEditor />}
          />
          <Route
            path={RoutePaths.IMAGE_BACKGROUND_REMOVAL}
            element={<ImageBackgroundRemoval />}
          />
          <Route
            path={RoutePaths.VIDEO_EDITOR_FULLSCREEN}
            element={<VideoEditorFunctions />}
          />
          <Route element={<ErrorLayout />}>
            <Route path={RoutePaths.NOT_FOUND} element={<Error404 />} />
            <Route path={RoutePaths.BROKEN_PAGE} element={<Error500 />} />
          </Route>
          <Route
            path={RoutePaths.SWITCH_WORKSPACE}
            element={<WorkspaceSwitcher />}
          />
          {/* Authentication Start */}
          <Route element={<AuthLayout />}>
            <Route path={RoutePaths.LOGIN} element={<AccountLogIn />} />
            <Route path={RoutePaths.REGISTER} element={<AccountRegister />} />
            {/* <Route path={RoutePaths.VERIFY_EMAIL_ON_SIGNUP} element={<VerifyEmailAddressOnSignup />} /> */}
            <Route
              path={RoutePaths.FORGOT_PASSWORD}
              element={<CardForgetPassword />}
            />
            <Route
              path={RoutePaths.RESET_PASSWORD}
              element={<CardPasswordReset />}
            />
            <Route
              path={RoutePaths.PASSWORD_LESS_SIGN_IN}
              element={<PasswordLessLogIn />}
            />
            <Route path={RoutePaths.LOGOUT} element={<AccountLogOut />} />
            <Route path={RoutePaths.MANDATORY_PLAN_UPGRADE} element={<MandatoryAccountUpgrade />} />
            <Route
              path={RoutePaths.LTD_PURCHASE_PAGE}
              element={<LTDPurchasePage />}
            />
            <Route
              path={RoutePaths.LTD_UPGRADE_PAGE}
              element={<LTDUpgradePage />}
            />
            <Route
              path={RoutePaths.TELEGRAM_LOGIN_PROXY}
              element={<TelegramLoginProxy />}
            />
            <Route
              path={RoutePaths.PAYMENT_PORTAL_PROXY}
              element={<PaymentPortalProxy />}
            />
          </Route>
          <Route
            path={RoutePaths.VERIFY_EMAIL_ON_SIGNUP}
            element={<VerifyEmailAddressOnSignup />}
          />
          {/* Authentication End */}
          <Route
            path={RoutePaths.INVITATIONS}
            element={<WorkspaceInvitations />}
          />
          <Route path={RoutePaths.VERIFY_NEW_EMAIL} element={<VerifyEmail />} />
          <Route path={RoutePaths.NEW_WORKSPACE} element={<NewWorkspace />} />
          <Route path={RoutePaths.ORGS_AND_WORKSPACES} element={<OrganizationAndWorkspaceSetup />} />
          <Route
            path={"/ai_writer"}
            element={<Navigate to={`${RoutePaths.AI_WRITER}?meta=${meta}`} />}
          />
          <Route
            path={"/ai_chat"}
            element={<Navigate to={`${RoutePaths.AI_CHAT}?meta=${meta}`} />}
          />
          <Route
            path={"/new"}
            element={<Navigate to={RoutePaths.NEW_WORKSPACE} />}
          />
          <Route
            path={"/platforms/telegram"}
            element={
              <Navigate
                to={`${RoutePaths.PLATFORMS}/telegram${localStorage.getItem("buy_customized_bot")
                  ? `/${localStorage.getItem("buy_customized_bot")}`
                  : ""
                  }`}
              />
            }
          />

          <Route
            path={RoutePaths.WHITELABEL_SETUP}
            element={<WhiteLabelSetup />}
          />
          <Route
            path={RoutePaths.WHITELABEL_PRODUCT_PLAN}
            element={<WhiteLabelStripeConnectPricingPage />}
          />
          <Route element={<MainLayout />}>
            <Route
              path={RoutePaths.METRICS}
              element={<Metrics />}
            />
            <Route
              path={RoutePaths.ALL_USERS}
              element={<Users />}
            />
            <Route
              path={RoutePaths.ADMIN_BOT_REQUESTS}
              element={<BotRequests />}
            />
            <Route
              path={RoutePaths.ALL_WORKSPACES}
              element={<Workspaces />}
            />
            <Route
              path={RoutePaths.POSTS}
              element={<PostsLayout postType="all" />}
            />
            <Route path={RoutePaths.SCHEDULE_A_POLL} element={<CreatePoll />} />
            {/* <Route path={RoutePaths.SCHEDULE_A_POLL} element={<PostsLayout postType="all" />} /> */}
            <Route
              path={RoutePaths.PUBLISHED_POSTS}
              element={<PublishedPost postType="posted" />}
            />
            <Route
              path={RoutePaths.SCHEDULED_POSTS}
              element={<ScheduledPost postType="scheduled" />}
            />
            <Route
              path={RoutePaths.RECURRING_POSTS}
              element={<RecurringPosts postType="recurring" />}
            />
            <Route
              path={RoutePaths.PENDING_APPROVAL}
              element={<PendingApprovalPosts postType="pending_approval" />}
            />
            <Route
              path={RoutePaths.DRAFTS_POSTS}
              element={<DraftedPost postType="draft" />}
            />
            <Route
              path={RoutePaths.FAILED_POSTS}
              element={<FailedPost postType="failed" />}
            />
            <Route
              path={RoutePaths.WHITELABEL_SETTINGS}
              element={<WhiteLabelSettings />}
            />
            <Route
              path={RoutePaths.MY_STRIPE_CONNECT}
              element={<StripeConnect />}
            />
            <Route
              path={RoutePaths.AI_LANGUAGE_TRANSLATOR}
              element={<LanguageTranslator />}
            />
            {/* <Route path={RoutePaths.PUBLISHED_POSTS} element={<PostsLayout postType="posted" />} /> */}
            {/* <Route path={RoutePaths.SCHEDULED_POSTS} element={<PostsLayout postType="scheduled" />} /> */}
            {/* <Route path={RoutePaths.RECURRING_POSTS} element={<PostsLayout postType="recurring" />} /> */}
            {/* <Route path={RoutePaths.PENDING_APPROVAL} element={<PostsLayout postType="pending_approval" />} /> */}
            {/* <Route path={RoutePaths.DRAFTS_POSTS} element={<PostsLayout postType="draft" />} /> */}
            {/*  */}
            <Route
              path={RoutePaths.PEXELS_CONTENTS}
              element={<PexelsPage fromWriteTab />}
            />
            <Route
              path={RoutePaths.SHUTTERSTOCK_CONTENTS}
              element={<ShutterStockPage fromWriteTab />}
            />
            <Route
              path={RoutePaths.GDRIVE_CONTENTS}
              element={<GoogleDrivePage />}
            />
            <Route
              path={`${RoutePaths.GDRIVE_CONTENTS}/:folder_id`}
              element={<GoogleDrivePage />}
            />
            <Route path={RoutePaths.POSTLY_CLOUD} element={<MediaCloud />} />
            <Route
              path={`${RoutePaths.POSTLY_CLOUD}/:parent_folder`}
              element={<MediaCloud />}
            />
            {/* <Route path={`${RoutePaths.POST_CONTENT_PREVIEW_EDITOR}/:platform`} element={<PreviewPageEditor />} /> */}
            <Route path={RoutePaths.RSS_FEED} element={<RSSFeed />} />
            {/*  */}
            <Route
              path={RoutePaths.FACEBOOK_ANALYTICS}
              element={<FacebookAnalytics />}
            />
            <Route
              path={RoutePaths.LINKEDIN_ANALYTICS}
              element={<LinkedInAnalytics />}
            />
            <Route
              path={RoutePaths.INSTAGRAM_ANALYTICS}
              element={<InstagramAnalytics />}
            />
            <Route
              path={RoutePaths.YOUTUBE_ANALYTICS}
              element={<YoutubeAnalytics />}
            />
            <Route path={RoutePaths.INBOX_MESSAGES} element={<SocialInbox />} />
            <Route path={RoutePaths.INBOX_COMMENTS} element={<SocialInbox />} />
            <Route
              path={`${RoutePaths.POSTS}/:path`}
              element={<PostsLayout postType="all" />}
            />
            <Route
              path={RoutePaths.BULK_POSTS}
              element={<BulkPostsCreator />}
            />
            <Route
              path={RoutePaths.WORKSPACE_SETTINGS}
              element={<WorkspaceSettings />}
            />
            <Route path={RoutePaths.PLATFORMS} element={<PlatformPageNew />} />
            {/* <Route path={RoutePaths.PLATFORMS} element={<PlatformsPage />} /> */}
            <Route
              path={`${RoutePaths.PLATFORMS}/:identifier`}
              element={<ConnectedAccountsPage />}
            />
            <Route
              path={`${RoutePaths.PLATFORMS}/:identifier/:accountInfo`}
              element={<ConnectedAccountsDataPage />}
            />
            <Route
              path={RoutePaths.AI_CHAT}
              element={<AIChat tools={tools} />}
            />

            {/* <Route
              path={RoutePaths.AI_WRITER}
              element={<AIWriter tools={tools} />}
            /> */}
            <Route
              path={RoutePaths.AI_DASHBOARD}
              element={<AISettings tools={tools} />}
            />
            {/* <Route
              path={`${RoutePaths.AI_WRITE}`}
              element={<PostWriter tools={tools} />}
            /> */}
            <Route
              path={`${RoutePaths.AI_IMAGE_EDITOR}`}
              element={<ImageEditor tools={tools} />}
            />
            <Route
              path={`${RoutePaths.AI_VIDEO_EDITOR}`}
              element={<VideoEditor tools={tools} />}
            />
            <Route
              path={`${RoutePaths.AI_WRITER}/:tool`}
              element={<AIWriterTool tools={tools} />}
            />
            <Route
              path={`${RoutePaths.AI_ART}`}
              element={<AIArtGenerator tools={art_tools} />}
            />
            <Route
              path={`${RoutePaths.AI_ART}/:tool`}
              element={<AIArtTool tools={art_tools} />}
            />
            <Route path={RoutePaths.TEAM} element={<TeamPage />} />
            <Route path={RoutePaths.ACCOUNT} element={<AccountPage />} />
            <Route path={RoutePaths.BILLING} element={<BillingPage />} />
            <Route
              path={RoutePaths.SCHEDULER_BILLING}
              element={<SchedulerBilling />}
            />
            <Route
              path={RoutePaths.ALL_BILLING}
              element={<AllBilling />}
            />
            <Route
              path={RoutePaths.BILLING_STRIPE}
              element={<BillingStripe />}
            />
            <Route
              path={RoutePaths.POSTLY_CLOUD_BILLING}
              element={<PostlyCloudBilling />}
            />
            <Route
              path={RoutePaths.UNLIMITED_AI_BILLING}
              element={<UnlimitedAIBilling />}
            />
            <Route
              path={RoutePaths.REDEEM_CODES}
              element={<RedeemCodePage />}
            />
            <Route
              path={RoutePaths.INVOICE_HISTORY}
              element={<InvoiceHistoryPage />}
            />

            <Route path={RoutePaths.PLANNER} element={<ContentPlanner />} />
            <Route path={RoutePaths.EVENT_PLANNER} element={<EventCalendar />} />
            <Route
              path={RoutePaths.BULK_WORKSPACES}
              element={<BulkWorkspacesCreator />}
            />
            <Route
              path={RoutePaths.LINK_SHORTENER}
              element={<LinkShortener />}
            />
            <Route
              path={RoutePaths.CAMPAIGN_BUILDER}
              element={<CampaignBuilder />}
            />
            <Route
              path={RoutePaths.REVIEW_LINK_GENERATOR}
              element={<ReviewLinkGenerator />}
            />
            <Route path={`${RoutePaths.GOOGLE_MY_BUSINESS_LOCATIONS}/:locationId`} element={<BusinessLocations />} />
            <Route path={RoutePaths.QR_CODE} element={<Qrcode />} />
            <Route path={RoutePaths.INTEGRATION} element={<Integrations />} />
            <Route
              path={RoutePaths.CUSTOM_DOMAIN}
              element={<CustomDomainSettingsPage />}
            />
            <Route
              path={RoutePaths.VIDEO_EDITOR}
              element={<VideoEditorPage />}
            />
            <Route
              path={RoutePaths.IMAGE_EDITOR}
              element={<ImageEditorPage />}
            />
            <Route path="*" element={<Navigate to={RoutePaths.POSTS} />} />
            <Route path="authentication-modal" element={<ModalAuth />} />
            <Route path="/deals" element={<Deals />} />
            <Route path="/help" element={<Help />} />
          </Route>
          <Route path="*" element={<MainLayout />} />
        </Routes>
      )}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
      <GlobalUpgradeModal />
    </>
  );
};
export default Layout;
