import { RoutePaths, Strings } from 'constants';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getActiveMetric, getCollectMetric } from 'redux/slices/analytics_slice';
import { getFreshNotificationPopup, setFreshNotificationPopup } from 'redux/slices/notification_slice';
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import Flex from '../Flex';


const ReminderModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const freshNotificationPopup = useSelector(getFreshNotificationPopup)
    const activeMetric = useSelector(getActiveMetric)
    const collectMetric = useSelector(getCollectMetric)
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const updateUserCheckboxResponse = async (userResponse, questionId) => {
        if (!userResponse) return

        let resObject = {
            key: questionId,
            value: userResponse
        }
        try {
            const response = await APIService.updateUserQuickResponse(resObject);
            const { data } = response;
            console.log("🚀 ~ APIService.updateUserQuickResponse ~ data:", data);
            dispatch(setLoggedInUser({ ...data }));
        } catch (error) {
            console.log(error);
        }
    }

    const closeModal = async () => {
        if (freshNotificationPopup?.optionToDisableFutureNotifications) {
            await updateUserCheckboxResponse(doNotShowAgain, freshNotificationPopup?.disableFutureNotificationsKey)
        }
        dispatch(setFreshNotificationPopup({
            show: false,
            message: ``,
            header: ``,
            optionToDisableFutureNotifications: false,
            type: 'info'
        }))
    }

    const handleSelection = (e) => {
        setDoNotShowAgain(e.target.checked)
    }

    const displayMessages = () => {
        return <Modal.Body>
            <p>
                {freshNotificationPopup?.message}
            </p>
            {
                freshNotificationPopup?.optionToDisableFutureNotifications &&
                <Form.Check
                    type="checkbox"
                    style={{
                        cursor: 'pointer',
                        marginTop: 2,
                    }}
                    label="Do not show again"
                    onChange={handleSelection}
                />
            }

        </Modal.Body>
    }

    useEffect(() => {
        if (freshNotificationPopup?.show && freshNotificationPopup?.optionToDisableFutureNotifications && loggedInUser['quick_responses'][freshNotificationPopup?.disableFutureNotificationsKey]) {
            dispatch(setFreshNotificationPopup({
                show: false,
                message: ``,
                header: ``,
                optionToDisableFutureNotifications: false,
                type: 'info'
            }))
        }
    }, [freshNotificationPopup])

    return (
        <div>
            <Modal
                size="md"
                centered
                show={freshNotificationPopup?.show}
                onHide={() => closeModal()}
            >
                <Modal.Header>
                    <span
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Modal.Title>
                                {freshNotificationPopup?.header}
                            </Modal.Title>
                        </div>
                    </span>
                </Modal.Header>
                {displayMessages()}
                <Modal.Footer style={{ flexDirection: "column" }}>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} gap={10}>
                        <Button
                            variant="danger"
                            onClick={closeModal}>
                            Not now
                        </Button>

                        <Button onClick={async () => {
                            if (freshNotificationPopup?.optionToDisableFutureNotifications) {
                                await updateUserCheckboxResponse(doNotShowAgain, freshNotificationPopup?.disableFutureNotificationsKey)
                            }
                            let routeTo = freshNotificationPopup?.routeOnAcceptance || RoutePaths.BILLING_STRIPE
                            dispatch(setFreshNotificationPopup({
                                show: false,
                                message: ``,
                                header: ``,
                                optionToDisableFutureNotifications: false,
                                type: 'info'
                            }))
                            navigate(routeTo)
                        }}>Buy now</Button>
                    </Flex>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ReminderModal